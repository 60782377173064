 
        <img mat-card-image src="../assets/img/AGC-VINYTHAI-Logo.png">
        <br>
        <img mat-card-image src="../assets/menu/divider.png">
        <br> 
        <img mat-card-image src="../assets/img/logoBrainbox.png" [style.width.%]="80" [style.paddingLeft.px]="20" >
        
    
      <p>
        Welcome to AVT Innovation
      </p>
      <p>
        <mat-form-field appearance="outline">
          <mat-label>User Name</mat-label>
           <!-- <input matInput placeholder="username">  -->
          <!--  <input matInput type="text" value="username">    -->
          <input matInput  type="text"   [value]="username" (change)="UserInput($event)" [style.color]="'white'" >
          <mat-icon class="material-symbols-outlined" matSuffix>account_circle</mat-icon>
         
        </mat-form-field>
      </p>
      <p>
        <mat-form-field appearance="outline">
          <mat-label>Password</mat-label>
          <!-- <input matInput placeholder="Placeholder"> -->
          <input matInput type="password"  [value]="password" (change)="PasswordInput($event)" >
          <mat-icon class="material-symbols-outlined" matSuffix>vpn_key</mat-icon>
        
        </mat-form-field>
      </p>
  
       

      <button mat-raised-button color="warn"  [style.width.px]="250"   (click)="ChangePassword()" >Forget Password</button>
      <br>
      <button mat-raised-button color="primary"  (click) ="Login()"  [style.width.px]="250" >Login
        <mat-icon class="material-symbols-outlined" matSuffix>vpn_key</mat-icon>
      </button>
     
    

