

<div class="edit-course-form">

    <h2 mat-dialog-title>Enter OTP from e-mail and  new password from change</h2>
<br>
<h2 mat-dialog-title> User name :{{username}}</h2>
<h2 mat-dialog-title> see otp on e-mail: {{email}} </h2>
    <mat-dialog-content [formGroup]="form">

       

        <mat-form-field appearance="outline">

            <input matInput
                   placeholder="OTP"
                   formControlName="otp" maxlength="6">

        </mat-form-field>

        <mat-form-field appearance="outline">
            <input matInput
                   placeholder="New Password"
                   formControlName="newPassword">
          

        </mat-form-field>

           

    </mat-dialog-content>

    <mat-dialog-actions>

        <button mat-raised-button (click)="close()">Close</button>

        <button mat-raised-button color="primary" (click)="save()">Update Password</button>

    </mat-dialog-actions>

</div>





