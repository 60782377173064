<div [formGroup]="form" class="course-details-form">


    <mat-form-field>


        <input matInput placeholder="Course title" formControlName="title" #title>

        <mat-hint align="end">

            {{title.value.length}} / 60

        </mat-hint>

        <mat-error *ngIf="courseTitle.errors?.minlength">

            A minimum length of 5 characters is required.

        </mat-error>


    </mat-form-field>

    <mat-radio-group class="course-type" formControlName="courseType">

        <mat-radio-button [disabled]="true" value="free">Free</mat-radio-button>

        <mat-radio-button value="premium">Premium</mat-radio-button>

    </mat-radio-group>

    <mat-form-field>

        <mat-select formControlName="category"
                    placeholder="Select category">

            <mat-option>None</mat-option>

            <mat-optgroup label="Beginners">

                <mat-option value="newprogramming">New To Programming</mat-option>
                <mat-option value="newjavascript">New To Javascript</mat-option>
                <mat-option value="newangular">New To Angular</mat-option>

            </mat-optgroup>

            <mat-option value="INTERMEDIATE">Intermediate</mat-option>
            <mat-option value="ADVANCED">Advanced</mat-option>

        </mat-select>

    </mat-form-field>

    <mat-form-field appearance="outline">

        <input matInput formControlName="releasedAt"
               placeholder="Release date" [matDatepicker]="releasedAtPicker">

        <mat-datepicker-toggle matSuffix [for]="releasedAtPicker">


        </mat-datepicker-toggle>

        <mat-datepicker #releasedAtPicker [dateClass]="dateClass">


        </mat-datepicker>


    </mat-form-field>

    <mat-checkbox color="primary" formControlName="downloadsAllowed"
        [indeterminate]="true">

        Downloads allowed?

    </mat-checkbox>

    <mat-form-field appearance="outline">

        <textarea matInput placeholder="Description" formControlName="longDescription"

                  cdkTextareaAutosize [cdkAutosizeMinRows]="2"
                  [cdkAutosizeMaxRows]="5">


        </textarea>

    </mat-form-field>


</div>



