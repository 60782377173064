import {ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {Course} from "../model/course";
import {Observable, Subject} from "rxjs";
import {CoursesService} from "../services/courses.service";
import {map} from "rxjs/operators";
import {AppService} from '../services/app.service'
import { Router , RouterOutlet} from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';


@Component({
    selector: 'menu-left',
    templateUrl: './menu-left.component.html',
    styleUrls: ['./menu-left.component.scss']
})
export class MenuLeftComponent implements OnInit {


    leftmenuSelected = 'home'
    beginnerCourses$: Observable<any>;

    advancedCourses$: Observable<Course[]>;
    HomePage:Subject<any> =  new Subject<any>()  ;
    currentScreenSize:string;
    captionlevelfontsize
    RankingSize:string = 'Large';
    cols=2
    lightimg:boolean= false;
    totalNumber = 'large'
    totallabel = 'small'
                                WelcomelabelfontSize ='32px'
                            UserNamelabelfontSize ='25px'
    pathD = 'M51,93.5C51,54.6,82.6,23,121.5,23h372.1c11.6,0,17.4,0,21.8,2.3c3.5,1.9,6.4,4.8,8.3,8.3C526,38,526,43.8,526,55.4v38.1v38.1c0,11.6,0,17.4-2.3,21.8c-1.9,3.5-4.8,6.4-8.3,8.3c-4.4,2.3-10.2,2.3-21.8,2.3H121.5	C82.6,164,51,132.4,51,93.5z';
    constructor(private AppService:AppService,
            public router: Router,
            public sanitized: DomSanitizer,     ) {
                
    }

    ngAfterViewInit(): void {
        //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
        //Add 'implements AfterViewInit' to the class.
   //     console.log('ngAfterViewInit')
      }

    ngOnInit() {
      const  that = this
   //   console.log('ngOnInit')
/*       this.Parent.subscribe(function(s){
        console.log(s)
        that.leftmenuSelected =s.replace('/','')   
       // that.cd.detectChanges();
      }) */
       this.AppService.variables.subscribe(function(v){
           // console.log(v)


            switch (v['eventname']) {
                case "ChangeScreenSize":
                    
                break;               
                case "VariablesChanging":
                   // console.log(v)       
                    switch (v['variablename']) {
                        case 'leftmenuSelected':
                            setTimeout(() => {
                                that.leftmenuSelected = v['value'].toString().replace('/','')    
                            }, 200);
                            
               //     console.log( that.leftmenuSelected )
                            break;
                    
                        default:
                            break;
                    }

                    break;
            
                default:
                    break;
            }

        }) 
        this.cols=3
        setTimeout(() => {
            that.setScreenSize()
        }, 200);
      
    }

    setScreenSize(){
       const that=this 
        switch (that.currentScreenSize) {
            case 'XLarge': 
                    that.cols=3;              
                    break;
            case 'Large': 
                    that.cols=3 ;
                    that.captionlevelfontsize='large';
                    that.RankingSize= 'Large'; 
                    that.totalNumber = 'large'
                    that.totallabel = 'x-small'
                    that.WelcomelabelfontSize ='32px'
                    that.UserNamelabelfontSize ='25px'
                    break;
            case 'Medium': 
                    that.cols=3;that.captionlevelfontsize='medium';
                    that.RankingSize= 'Medium';  
                    that.totalNumber = 'small'
                    that.totallabel = 'xx-small'
                    that.WelcomelabelfontSize ='25px'
                    that.UserNamelabelfontSize ='20px'
                    break;
            case 'Small': 
                    that.cols=3; that.captionlevelfontsize='medium'; 
                    that.RankingSize= 'Small'; 
                    that.totalNumber = 'x-small'
                    that.totallabel = 'xx-small'
                    that.WelcomelabelfontSize ='25px'
                    that.UserNamelabelfontSize ='20px'
                    break;
            case 'XSmall': 
                    that.cols=1;that.captionlevelfontsize='Smaller'; 
                    that.RankingSize= 'Large';                             
                    that.WelcomelabelfontSize ='32px'
                    that.UserNamelabelfontSize ='25px'
                    break;
            
        
            default:
                break;
        }
        setTimeout(() => {
            
            that.HomePage.next({eventname:'ChangeScreenSize',value:{RankingSize:that.RankingSize} })
        }, 200);
    }
    lightOpend (ev,open){
       // console.log(open)
        this.lightimg=open;    
        
    }

    getSelect(){
        return this.leftmenuSelected
    }
    getisactiveMenuText(menu){
        let _selected = this.leftmenuSelected
       // console.log('isactiveMenuText:' , this.isactiveMenuText , '==' ,menu )
        return _selected.indexOf(menu) >-1 ;
      }
      getNotactiveMenu(menu){
        let _selected = this.leftmenuSelected
      //  console.log('isactiveMenuText:' , this.isactiveMenuText , '==' ,menu )
        return _selected.indexOf(menu) <0;
      }

}
