

<div class="edit-course-form">

    <h2 mat-dialog-title>enter username and e-mail for recive OTP </h2>

    <mat-dialog-content [formGroup]="form" >

        <mat-form-field appearance="outline" >

            <input matInput
                   placeholder="username"
                   formControlName="username">

        </mat-form-field>

        <mat-form-field appearance="outline" >
            <input matInput
                   placeholder="email"
                   formControlName="email"  >
          

        </mat-form-field>

      

    </mat-dialog-content>

    <mat-dialog-actions>

        <button mat-raised-button (click)="close()">Close</button>

        <button mat-raised-button color="primary" (click)="save()">Chage Password</button>

    </mat-dialog-actions>

</div>





