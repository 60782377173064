

  <div class="mat-elevation-z8" >
    
    <mat-form-field  appearance="outline" >
      <mat-select [style.border-color]="'white'"   >
        <mat-option>-- None --</mat-option>
        <mat-option value="option">Option</mat-option>
      </mat-select>
      <mat-label>Status:</mat-label>
    </mat-form-field>
    <mat-form-field  appearance="outline" >
      <mat-select [style.border-color]="'white'"   >
        <mat-option>-- None --</mat-option>
        <mat-option value="option">Option</mat-option>
      </mat-select>
      <mat-label>Category</mat-label>
    </mat-form-field>
    <mat-form-field  appearance="outline" >
      <mat-select [style.border-color]="'white'"   >
        <mat-option>-- None --</mat-option>
        <mat-option value="option">Option</mat-option>
      </mat-select>
      <mat-label>Approver</mat-label>
    </mat-form-field>
    <mat-form-field  appearance="outline" >
      <mat-select [style.border-color]="'white'"   >
        <mat-option>-- None --</mat-option>
        <mat-option value="option">Option</mat-option>
      </mat-select>
      <mat-label>Coordinator</mat-label>
    </mat-form-field>
    <mat-form-field  appearance="outline">
      <mat-icon class="material-symbols-outlined" matPrefix >search</mat-icon>
      <input matInput (keyup)="applyFilter($event)" placeholder="Search" #input>
      <mat-label matSuffix >  Search</mat-label>
    </mat-form-field>
    <table mat-table [dataSource]="dataSource" matSort #tabledetail >

      <!-- ID Column -->
      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> ID </th>
        <td mat-cell *matCellDef="let row" [style.border-bottom]="rowboderstyle" > {{row.id}} </td>
      </ng-container>
  
      <!-- Progress Column -->
      <ng-container matColumnDef="progress">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Progress </th>
        <td mat-cell *matCellDef="let row" [style.border-bottom]="rowboderstyle" [style.border-bottom]="'0.5px solid gray'"> {{row.progress}}% </td>
      </ng-container>
  
      <!-- Name Column -->
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
        <td mat-cell *matCellDef="let row" [style.border-bottom]="rowboderstyle"> {{row.name}} </td>
      </ng-container>
  
      <!-- Fruit Column -->
      <ng-container matColumnDef="fruit">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Fruit </th>
        <td mat-cell *matCellDef="let row" [style.border-bottom]="rowboderstyle"> {{row.fruit}} </td>
      </ng-container>
  
      <tr mat-header-row *matHeaderRowDef="displayedColumns" style=" border-color: white" ></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;" [style.border-bottom]="'0.5px solid gray'"></tr>
  
      <!-- Row shown when there is no matching data. -->
      <tr class="mat-row" *matNoDataRow >
        <td class="mat-cell" colspan="4" >No data matching the filter "{{input.value}}"</td>
      </tr>
    </table>
  
    
    
  </div>
  <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]" aria-label="Select page of users"></mat-paginator>

