 
 <div class="main">
  <!-- <img mat-card-image src="../assets/img/login-bg3.jpg"> -->
   <div class="bigtext">
    <p class="bigtext">
      Growing with You
    </p>     
    <p class="bigtext">
      Growing with Innovation
    </p>     
    <p>
      Empower all our employees to demonstrate "can do" spirit, 
    commitment and accountability 
    </p>
   </div>

 </div>
 