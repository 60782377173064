import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, ElementRef, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {AppService} from './services/app.service'
import { Router, RouterOutlet } from '@angular/router';
import { MatListItem } from '@angular/material/list';
import { DomSanitizer } from '@angular/platform-browser';
import { UserService } from './services/user.service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {

  currentScreenSize
  showmenu:boolean =true;
  isactiveMenuText :string = 'login';
  leftmenuSelected = 'login'
  userLogined:boolean =false;
  destroyed = new Subject<void>();
  dyanmicHtmlString:string = '<buton></buton>';
  Me:Subject<any> =  new Subject<any>()  ;

  @ViewChild( 'menuhome') menuList: ElementRef;

  constructor( breakpointObserver: BreakpointObserver,
    private  AppService:AppService,  
    public router: Router,
    private elem: ElementRef,
    public sanitized: DomSanitizer,
    public user:UserService
  ){
     
    breakpointObserver
          .observe([
            Breakpoints.XSmall,
            Breakpoints.Small,
            Breakpoints.Medium,
            Breakpoints.Large,
            Breakpoints.XLarge,
          ])
          .pipe(takeUntil(this.destroyed))
          .subscribe(result => {
            for (const query of Object.keys(result.breakpoints)) {
              if (result.breakpoints[query]) {
                this.currentScreenSize = this.displayNameMap.get(query) ?? 'Unknown';
              }
            }

           // console.log(this.currentScreenSize)
  
            this.setScreenSize()
          
          });

          
  this.router.events.subscribe(function(ev){
    if(ev['type']==1){
     console.log(ev)
    //  console.log('menuList', this.menuList )
      
      
      if(!this.userLogined){
        router.navigate(['/login'])
      } else{
        if(ev['url']){
          //  this.dyanmicHtmlString = sanitized.bypassSecurityTrustHtml('<label>dddd </label>');
          AppService.setVariable('leftmenuSelected',ev['url'])
            
          }
      }


    }

    
  })
  }
  displayNameMap = new Map([
    [Breakpoints.XSmall, 'XSmall'],
    [Breakpoints.Small, 'Small'],
    [Breakpoints.Medium, 'Medium'],
    [Breakpoints.Large, 'Large'],
    [Breakpoints.XLarge, 'XLarge'],
  ]);

  @ViewChild('sidenav') sidenav;
  
  ngOnInit() {
    console.log('ngOnInit')
    this.setScreenSize()
    const that = this
   // this.userLogined = false

   let _checkToken = this.user.checkUserToken()
   console.log(_checkToken)
   /* 
    if (!this.AppService.LocalGetValue('UserToken')){
      console.log('New Login')
     // this.AppService.LocalSetValue('UserToken','**GenNewKey**')
      this.userLogined = false
    } else {
      let UserToken =  this.AppService.LocalGetValue('UserToken')
      //this.userLogined = true
      console.log('UserToken:',UserToken)
    } */

    this.AppService.variables.subscribe(function(event){  
      console.log(event)
       if (event.eventname=='VariablesChanging'){
        switch (event.variablename) {
          case 'leftmenuSelected':
                that.isactiveMenuText =event.value
                console.log('event.value:',event.value)
                console.log('isactiveMenuText:', that.isactiveMenuText)
                setTimeout(() => {
                  that.Me.next(that.isactiveMenuText)
                }, 100);
            break;
            case 'CurrentUser':

              that.userLogined = true
              that.isactiveMenuText = 'home';
              that.leftmenuSelected = 'home';
              that.router.navigate(['/home'])
          break;       
          default:
            break;
        }
        
       
      } 
    })

  }
  ngAfterViewInit(): void {
 
  }

  getAppVar(varname){
   return this.AppService.getVariable(varname)
  }

   getisactiveMenuText(menu){
    let _selected = this.getAppVar('leftmenuSelected')
   // console.log('isactiveMenuText:' , this.isactiveMenuText , '==' ,menu )
    return _selected.indexOf(menu) >-1 ;
  }
  getNotactiveMenu(menu){
    let _selected = this.getAppVar('leftmenuSelected')
  //  console.log('isactiveMenuText:' , this.isactiveMenuText , '==' ,menu )
    return _selected.indexOf(menu) <0;
  }
  ngOnDestroy() {
    this.destroyed.next();
    this.destroyed.complete();
  }

  setScreenSize(){
    setTimeout(() => {
      this.AppService.setScreenSize(this.currentScreenSize)
      switch (this.currentScreenSize) {
        case 'XLarge': this.showmenu=true;              
        case 'Large': this.showmenu=true ; break;
        case 'Medium': this.showmenu=true;  break;
        case 'Small': this.showmenu=false;  break;
        case 'XSmall':this.showmenu=false;  break;
        
    
        default:
            break;
    }
      if(this.showmenu==false){
        this.sidenav.close()
      } else {
        this.sidenav.open()
      }

    }, 300);
  }

}
