

<mat-grid-list [cols]="cols" rowHeight="270px" >
  
    <mat-grid-tile  [colspan]="1"   [rowspan]="1" (mouseover)="lightOpend($event,true)" (mouseleave)="lightOpend($event,false)" > 
      <p class="lighton"  *ngIf="lightimg==true"   ></p>
      <img mat-card-image class="light" *ngIf="lightimg==true" src="../assets/img/light-bulb-on.png"  style="width: auto;height: 100%;"   >
      <img mat-card-image class="light" *ngIf="lightimg!=true" src="../assets/img/light-bulb-off.png"  style="width: auto;height: 100%;  "   >
      <label style="color: white;position: absolute;top:50%;font-size: x-small;" >  Click Here For </label>      
      <label style="color: white;position: absolute;top:55%;font-size: x-small;" > Create New Ideas!</label>
      <img mat-card-image class="light" *ngIf="lightimg==true" src="../assets/img/NewIdeas.png"  style="width: auto;height: 50px; top:40% "   >
    </mat-grid-tile>
    <mat-grid-tile    [colspan]="1"   [rowspan]="1"  > 

        <div class="ranking">            
              <p [style.margin.px]="1"  [style.fontSize] = "WelcomelabelfontSize">Welcome!</p>
              <p [style.margin.px]="1" [style.fontSize] = "UserNamelabelfontSize">Onnalin Chakthong</p>         
              <ranking  style="width: 100%;height: auto;" [Size]="RankingSize"  [Parent]="HomePage" ></ranking> 
        </div>
        <div class="rankingtotal" style="bottom:2px; right: 20px;  width: 70%;  " >            
          <table >
            <tr [style.fontSize] = "totallabel" >
              <td style=" width: 33%;">Total point</td>
              <td rowspan="3" [style.backgroundColor]="'white'" ></td>
              <td >Own Ideas</td>
              <td rowspan="3" [style.backgroundColor]="'white'" ></td>
              <td style=" width: 40%;" >Participate Ideas</td>
            </tr>
            <tr  [style.fontSize] = "totalNumber" >
              <td>336</td>
              <td  >80</td>
              <td  > 321</td>
            </tr>
            <tr [style.fontSize] = "totallabel">
              <td>Point(s)/Year</td>
              <td>Idea(s)</td>
              <td >Idea(s)</td>
            </tr>
          </table>
         </div>

    </mat-grid-tile>
    <mat-grid-tile [style.borderLeft]="'solid 3px'"  [style.borderColor]="'white'"   [colspan]="1"   [rowspan]="1"  >  
      <table>
        <tr [style.fontSize] = '20'   [style.color] = "'white'" >
          <td  >Your status   ---------</td>
          <td>--------------------------- </td>
        </tr>
        <tr>
          <td><status-box [Value] = "999" [Caption] = "'Draft'" style="width: 100%;height: auto;" [Size]="RankingSize"  [Parent]="HomePage" ></status-box></td>
          <td><status-box [Value] = "2" [Caption] = "'Wait'" style="width: 100%;height: auto;" [Size]="RankingSize"  [Parent]="HomePage" ></status-box></td>
        </tr>
        <tr>
          <td><status-box [Value] = "10" [Caption] = "'Approve'" style="width: 100%;height: auto;" [Size]="RankingSize"  [Parent]="HomePage" ></status-box></td>
          <td><status-box [Value] = "12" [Caption] = "'Not Applied'" style="width: 100%;height: auto;" [Size]="RankingSize"  [Parent]="HomePage" ></status-box></td>
        </tr>
        <tr>
          <td><status-box [Value] = "42" [Caption] = "'Ask more'" style="width: 100%;height: auto;" [Size]="RankingSize"  [Parent]="HomePage" ></status-box></td>
          <td><status-box [Value] = "5" [Caption] = "'Defend'" style="width: 100%;height: auto;" [Size]="RankingSize"  [Parent]="HomePage" ></status-box></td>
        </tr>
        <tr>
          <td><status-box [Value] = "2345" [Caption] = "'Applied'" style="width: 100%;height: auto;" [Size]="RankingSize"  [Parent]="HomePage" ></status-box></td>
          <td></td>
        </tr>
      </table>
      
    </mat-grid-tile>
    <mat-grid-tile    [colspan]="cols"   [rowspan]="2"  > 
      <table-my-ideas style="width: 100%;margin-left: 30px;" >

      </table-my-ideas> 
    </mat-grid-tile>
   
 
</mat-grid-list>
 
 

