import * as i0 from '@angular/core';
import { Injectable } from '@angular/core';

/**
 * 判断是否在浏览器中渲染Angular应用
 * `decorator` 无法直接使用Angular `PLATFORM_ID` 标识
 * 这带来的好处是当服务端自行填充 Document 时也能很好的识别
 */
const isBrowser = typeof document === 'object' && !!document;
class StorageUtil {
  static get(storage, key) {
    if (storage == null) {
      return null;
    }
    const value = StorageUtil.parse(storage.getItem(key) || 'null') || null;
    if (value === null) {
      return null;
    }
    if (typeof value === 'object' && typeof value._expired !== 'undefined' && value._expired !== 0 && +new Date() > value._expired) {
      StorageUtil.remove(storage, key);
      return null;
    }
    return value._value || null;
  }
  static set(storage, key, value, expiredAt = 0, expiredUnit = 't') {
    if (storage == null) {
      return;
    }
    storage.setItem(key, StorageUtil.stringify({
      _expired: StorageUtil.getExpired(expiredAt, expiredUnit),
      _value: value
    }));
  }
  static remove(storage, key) {
    if (storage == null) {
      return;
    }
    storage.removeItem(key);
  }
  static key(storage, index) {
    if (storage == null) {
      return null;
    }
    return storage.key(index);
  }
  static getExpired(val, unit) {
    if (val <= 0) {
      return 0;
    }
    const now = +new Date();
    switch (unit) {
      case 's':
        // 秒
        return now + 1000 * val;
      case 'm':
        // 分
        return now + 1000 * 60 * val;
      case 'h':
        // 时
        return now + 1000 * 60 * 60 * val;
      case 'd':
        // 天
        return now + 1000 * 60 * 60 * 24 * val;
      case 'w':
        // 周
        return now + 1000 * 60 * 60 * 24 * 7 * val;
      case 'y':
        // 年
        return now + 1000 * 60 * 60 * 24 * 365 * val;
      case 't':
        // 自定义
        return now + val;
      default:
        return 0;
    }
  }
  static stringify(value) {
    return JSON.stringify(value);
  }
  static parse(text) {
    try {
      return JSON.parse(text) || null;
    } catch (e) {
      return text;
    }
  }
}
const cache = {};
function WebStorage(storage, key, expiredAt = 0, expiredUnit = 'd') {
  return (target, propertyName) => {
    key = key || propertyName;
    Object.defineProperty(target, propertyName, {
      get: () => {
        return StorageUtil.get(storage, key);
      },
      set: value => {
        if (!cache[key]) {
          const storedValue = StorageUtil.get(storage, key);
          if (storedValue === null) {
            StorageUtil.set(storage, key, value, expiredAt, expiredUnit);
          }
          cache[key] = true;
          return;
        }
        StorageUtil.set(storage, key, value, expiredAt, expiredUnit);
      },
      enumerable: true,
      configurable: true
    });
  };
}
/**
 * `localStorage` Decorator
 *
 * @param [expiredAt=0] Expiration time, 0 means forever
 * @param [expiredUnit='t'] Expiration time unit (default: custom [unit: ms])
 */
function LocalStorage(key, expiredAt = 0, expiredUnit = 't') {
  return WebStorage(isBrowser ? localStorage : null, key, expiredAt, expiredUnit);
}
/**
 * `sessionStorage` Decorator
 *
 * @param [expiredAt=0] Expiration time, 0 means forever
 * @param [expiredUnit='t'] Expiration time unit (default: custom [unit: ms])
 */
function SessionStorage(key, expiredAt = 0, expiredUnit = 't') {
  return WebStorage(isBrowser ? sessionStorage : null, key, expiredAt, expiredUnit);
}
class StorageService {
  constructor(storage) {
    this.storage = storage;
  }
  get(key) {
    return StorageUtil.get(this.storage, key);
  }
  set(key, value, expiredAt = 0, expiredUnit = 'd') {
    return StorageUtil.set(this.storage, key, value, expiredAt, expiredUnit);
  }
  /**
   * 删除指定key，如：
   * - `remove('key')` 删除 `key` 键
   * - `remove(/BMap_\w+/)` 批量删除所有 BMap_ 开头的键
   * @param key 键名或正则表达式
   */
  remove(key) {
    if (typeof key === 'string') {
      StorageUtil.remove(this.storage, key);
      return;
    }
    let index = 0;
    let next = StorageUtil.key(this.storage, index);
    const ls = [];
    while (next) {
      if (key.test(next)) {
        ls.push(next);
      }
      next = StorageUtil.key(this.storage, ++index);
    }
    ls.forEach(v => StorageUtil.remove(this.storage, v));
  }
  clear() {
    this.storage?.clear();
  }
  static {
    this.ɵfac = function StorageService_Factory(t) {
      return new (t || StorageService)(i0.ɵɵinject(Storage));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: StorageService,
      factory: StorageService.ɵfac
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(StorageService, [{
    type: Injectable
  }], () => [{
    type: Storage
  }], null);
})();
class LocalStorageService extends StorageService {
  constructor() {
    super(isBrowser ? localStorage : null);
  }
  static {
    this.ɵfac = function LocalStorageService_Factory(t) {
      return new (t || LocalStorageService)();
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: LocalStorageService,
      factory: LocalStorageService.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(LocalStorageService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [], null);
})();
class SessionStorageService extends StorageService {
  constructor() {
    super(isBrowser ? sessionStorage : null);
  }
  static {
    this.ɵfac = function SessionStorageService_Factory(t) {
      return new (t || SessionStorageService)();
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: SessionStorageService,
      factory: SessionStorageService.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SessionStorageService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [], null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { LocalStorage, LocalStorageService, SessionStorage, SessionStorageService, StorageService, StorageUtil, isBrowser };
