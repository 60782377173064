import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {MatMenuModule} from '@angular/material/menu';
import { MatButtonModule} from '@angular/material/button'
import {MatIconModule} from '@angular/material/icon';
import {MatCardModule} from '@angular/material/card';
import { HomeComponent } from './home/home.component';
import { AboutComponent } from './about/about.component';
import {MatTabsModule} from '@angular/material/tabs';
import { CoursesCardListComponent } from './courses-card-list/courses-card-list.component';
import {CourseComponent} from "./course/course.component";
import { MatDatepickerModule } from "@angular/material/datepicker";
import {  MatDialogModule } from "@angular/material/dialog";
import {  MatInputModule } from "@angular/material/input";
import {  MatListModule } from "@angular/material/list";
import {  MatPaginatorModule } from "@angular/material/paginator";
import {  MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import {  MatSelectModule } from "@angular/material/select";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatSortModule } from "@angular/material/sort";
import {  MatTableModule } from "@angular/material/table";
import { MatToolbarModule } from "@angular/material/toolbar";

import {CoursesService} from "./services/courses.service";
import {AppService} from "./services/app.service";
import {UserService} from "./services/user.service";
import {PostgreSQLService} from "./services/pg.service";
import {SnackBarService} from "./services/snack-bar/snack-bar.service"
import {SnackBarComponent} from "./services/snack-bar//snack-bar.component"

import { provideHttpClient, withInterceptorsFromDi } from "@angular/common/http";
import { CourseDialogComponent } from './course-dialog/course-dialog.component';
import { ReactiveFormsModule} from "@angular/forms";
import {CreateCourseComponent} from './create-course/create-course.component';
import {MatStepperModule} from '@angular/material/stepper';
import {CreateCourseStep1Component} from './create-course/create-course-step-1/create-course-step-1.component';
import {CreateCourseStep2Component} from './create-course/create-course-step-2/create-course-step-2.component';
import { MatCheckboxModule} from '@angular/material/checkbox';
import { MatRadioModule} from '@angular/material/radio';
import {MatNativeDateModule} from '@angular/material/core';
import { MatSliderModule} from '@angular/material/slider';
import { MatTooltipModule} from '@angular/material/tooltip';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {DragDropComponent} from './drag-drop/drag-drop.component';

import {MatGridListModule} from '@angular/material/grid-list';
import {TreeDemoComponent} from './tree-demo/tree-demo.component';
import {MatTreeModule} from '@angular/material/tree';
import {VirtualScrollingComponent} from './virtual-scrolling/virtual-scrolling.component';
import {ScrollingModule} from '@angular/cdk/scrolling';

import {ProgressBoxComponent} from './progressbox/progressbox.component';
import {RankingComponent} from './ranking/ranking.component';
import {StatusBoxComponent} from './statusbox/statusbox.component';
import {TableMyIdeasComponent} from './home/table-my-ideas/table-my-ideas.component';
import {IdeasComponent  } from "./ideas/ideas.component";
import{DashboardComponent} from "./dashboard/dashboard.component"
import{ReportsComponent} from "./reports/reports.component"
import{ProfileComponent} from "./profile/profile.component"
import{ConfigComponent} from "./config/config.component"
import{ManualComponent} from "./manual/manual.component"
import{HallOfFameComponent} from "./hall-of-fame/hall-of-fame.component"
import {TableListIdeasComponent} from "./ideas/table-list-ideas/table-list-ideas.component"
import {MenuLeftComponent} from "./menu-left/menu-left.component"
import {LoginPageComponent} from "./login/login-page.component"
import {LoginComponent} from "./login/login.component"

import {GetOTPDialogComponent} from "./dialogs/getotp-dialog.component"
import {SetPasswordDialogComponent} from "./dialogs/setpassword-dialog.component"

@NgModule({ declarations: [
    IdeasComponent,
    DashboardComponent,
    ReportsComponent,
    ProfileComponent,
    ConfigComponent,
    ManualComponent,
    HallOfFameComponent,
        AppComponent,
        HomeComponent,
        AboutComponent,
        CourseComponent,
        CoursesCardListComponent,
        CourseDialogComponent,
        CreateCourseComponent,
        CreateCourseStep1Component,
        CreateCourseStep2Component,
        DragDropComponent,
        TreeDemoComponent,
        VirtualScrollingComponent,
        ProgressBoxComponent,
        RankingComponent,
        StatusBoxComponent,
        TableMyIdeasComponent,
        TableListIdeasComponent,
        MenuLeftComponent,
        LoginPageComponent,
        LoginComponent,
        GetOTPDialogComponent,
        SetPasswordDialogComponent,
        SnackBarComponent
    ],
    bootstrap: [AppComponent], imports: [BrowserModule,
        BrowserAnimationsModule,
        MatMenuModule,
        MatButtonModule,
        MatIconModule,
        MatCardModule,
        MatTabsModule,
        MatSidenavModule,
        MatListModule,
        MatToolbarModule,
        MatTooltipModule,
        MatInputModule,
        MatTableModule,
        MatPaginatorModule,
        MatSortModule,
        MatProgressSpinnerModule,
        MatStepperModule,
        MatDialogModule,
        AppRoutingModule,
        MatSelectModule,
        MatCheckboxModule,
        DragDropModule,
        MatRadioModule,
        MatSliderModule,
        MatDatepickerModule,
        MatNativeDateModule,
        ReactiveFormsModule,
        MatGridListModule,
        MatTreeModule,
        ScrollingModule],
     providers: [
        CoursesService,
        AppService,
        UserService,
        PostgreSQLService,
        SnackBarService,
        provideHttpClient(withInterceptorsFromDi())
    ] })
export class AppModule {
}
