

<div class="edit-course-form">

    <h2 mat-dialog-title>{{description}}</h2>

    <mat-dialog-content [formGroup]="form">

        <mat-form-field>

            <input matInput
                   placeholder="Course Description"
                   formControlName="description">

        </mat-form-field>

        <mat-form-field>

            <mat-select placeholder="Select category"
                        formControlName="category">

                <mat-option value="BEGINNER">
                    Beginner</mat-option>
                <mat-option value="INTERMEDIATE">
                    Intermediate</mat-option>
                <mat-option value="ADVANCED">
                    Advanced</mat-option>

            </mat-select>

        </mat-form-field>

        <mat-form-field>

            <input matInput [matDatepicker]="myDatepicker"
                   formControlName="releasedAt">

            <mat-datepicker-toggle matSuffix
                                   [for]="myDatepicker">

            </mat-datepicker-toggle>

            <mat-datepicker #myDatepicker></mat-datepicker>

        </mat-form-field>

        <mat-form-field>

        <textarea matInput placeholder="Description"
                  formControlName="longDescription">

        </textarea>

        </mat-form-field>

    </mat-dialog-content>

    <mat-dialog-actions>

        <button mat-raised-button (click)="close()">Close</button>

        <button mat-raised-button color="primary" (click)="save()">Save</button>

    </mat-dialog-actions>

</div>





