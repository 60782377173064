import {Component, Inject, OnInit, ViewEncapsulation} from '@angular/core';
import {MAT_DIALOG_DATA,  MatDialog,  MatDialogConfig,  MatDialogRef} from '@angular/material/dialog';
import {Course} from "../model/course";
import {FormBuilder, Validators, FormGroup} from "@angular/forms";
import * as moment from 'moment';

@Component({
    selector: 'setpassword-dialog',
    templateUrl: './setpassword-dialog.component.html',
    styleUrls: ['./setpassword-dialog.component.css']
})
export class SetPasswordDialogComponent implements OnInit {

    description:string;
    username:string;
    email:string;
    form = this.fb.group({
        username: [this.userinfo.username, Validators.required],
        email: [this.userinfo.email,  Validators.required],
        otp: [this.userinfo.otp,  Validators.required],
        newPassword: [this.userinfo.newPassword,  Validators.required],
        ReplyPassword: [this.userinfo.ReplyPassword,  Validators.required],
      
    });

    constructor(private fb: FormBuilder,
                @Inject(MAT_DIALOG_DATA) private userinfo:any,
                private dialogRef: MatDialogRef<SetPasswordDialogComponent>) {

        

    }

    ngOnInit() {
         
        this.username =this.form.value.username
        this.email =this.form.value.email
    }

    close() {

        this.dialogRef.close();

    }

    save() {

        this.dialogRef.close(this.form.value);

    }
}


export function openSetPasswordDialog(dialog: MatDialog, userinfo:any) {

    const config = new MatDialogConfig();

    config.disableClose = true;
    config.autoFocus = true;
    config.panelClass = "modal-panel";
    config.backdropClass = "backdrop-modal-panel";

    config.data = {
        ...userinfo
    };

    
   // this.username = config.data['username']
   // this.email = config.data['email']
    const dialogRef = dialog.open(SetPasswordDialogComponent, config);

    return dialogRef.afterClosed();
}









