

<mat-sidenav-container style="min-height: 100%; overflow: visible;" >
    
    <mat-sidenav  mode="side"   #sidenav [style.width.px]="260">
       <menu-left *ngIf="userLogined" >

       </menu-left >
       <login *ngIf="!userLogined" [style.width.px]="250">

       </login>

    </mat-sidenav>
  
    <mat-toolbar color="primary"  *ngIf="userLogined">

   <!--      <button mat-icon-button (click)="sidenav.open()">
            <mat-icon class="material-symbols-outlined">menu</mat-icon>
        </button>  -->
        <span class="example-spacer"></span>
        
        <a mat-button>
            <mat-icon class="material-symbols-outlined">person</mat-icon>
        
        </a>
        <a mat-button>
            <mat-icon class="material-symbols-outlined">settings</mat-icon>
        
        </a>
        <a mat-button>
            <mat-icon class="material-symbols-outlined">notification_important</mat-icon>
        
        </a>

    </mat-toolbar>


    <router-outlet></router-outlet>
 

</mat-sidenav-container>
