<div>
   <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
   viewBox="0 0 334 60" style="enable-background:new 0 0 334 60;" xml:space="preserve">
   <style type="text/css">
   .st0{fill:url(#SVGID_1_);}
   .st1{fill:url(#SVGID_00000085233004811698775130000008281745501244698514_);}
   </style>
   <defs>
      <filter id="f1">
        <feDropShadow dx="2" dy="3" stdDeviation="2" flood-opacity="0.7"/>
      </filter>
      <filter id="f2">
         <feDropShadow dx="1" dy="1" stdDeviation="1" flood-opacity="0.5"/>
       </filter>
    </defs>
   <g>
   
      <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="4.7105" y1="740.3684" x2="319.7105" y2="740.3684" gradientTransform="matrix(1 0 0 -1 0 770)">
      <stop  offset="0" style="stop-color:#C4C4C4"/>
      <stop  offset="0.5" style="stop-color:#FFFFFF"/>
      <stop  offset="1" style="stop-color:#7D7D7D"/>
   </linearGradient>
   <path class="st0" d="M4.7,29.6c0-10.8,8.7-19.5,19.5-19.5h276c10.8,0,19.5,8.7,19.5,19.5c0,10.8-8.7,19.5-19.5,19.5h-276
      C13.4,49.1,4.7,40.4,4.7,29.6z" filter="url(#f1)"/>
   </g>
   <linearGradient id="SVGID_00000168824053710943624380000010116062321049313188_" gradientUnits="userSpaceOnUse" x1="9.9136" y1="740.3691" x2="246.9645" y2="740.3691" gradientTransform="matrix(1 0 0 -1 0 770)">
   <stop  offset="0" style="stop-color:#2684FF"/>
   <stop  offset="1" style="stop-color:#113595"/>
   </linearGradient>
   <path [attr.d]="d"  fill="none"/>
    <path style="fill:url(#SVGID_00000168824053710943624380000010116062321049313188_);" [attr.d]="d"/> 
    <text x="20" y="35" fill="white" font-size="20" filter="url(#f1)">{{this.persentvalue}}</text>
    <text x="260" y="35" fill="#5e5f61" font-size="20" filter="url(#f2)">100pt</text>
   </svg>

  
   
</div>
