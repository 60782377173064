

import {Injectable} from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import {Observable} from "rxjs";
import {Course} from "../model/course";
import {map} from "rxjs/operators";
import {Lesson} from "../model/lesson";


@Injectable()
export class PostgreSQLService {

    endpoint ='https://api.alonersoft.com:28143'
    constructor(private http:HttpClient) {

    }

    findCourseById(courseId: number): Observable<Course> {
        return this.http.get<Course>(`/api/courses/${courseId}`);
    }

    findAllCourses(): Observable<Course[]> {
        return this.http.get(this.endpoint + '/db/courses')
            .pipe(
                map(res => res['payload'])
            );
    }

    findAllCourseLessons(courseId:number): Observable<Lesson[]> {
        return this.http.get(this.endpoint + '/db/lessons', {
            params: new HttpParams()
                .set('courseId', courseId.toString())
                .set('pageNumber', "0")
                .set('pageSize', "1000")
        }).pipe(
            map(res =>  res["payload"])
        );
    }

    findLessons(
        courseId:number, sortOrder = 'asc',
        pageNumber = 0, pageSize = 3, sortColumn = 'seqNo'):  Observable<Lesson[]> {

        return this.http.get(this.endpoint + '/db/lessons', {
            params: new HttpParams()
                .set('courseId', courseId.toString())
                .set('sortOrder', sortOrder)
                .set('pageNumber', pageNumber.toString())
                .set('pageSize', pageSize.toString())
                .set('sortColumn', sortColumn)
        }).pipe(
            map(res =>  res["payload"])
        );
    }

    CallProcedure(ProcedureName:string, ParameterInput:any ): Observable<any> {
        return this.http.post(this.endpoint + '/db/pg-call-proc', {jsonparm:ParameterInput}).pipe(
            map(res =>  res["output"])
        );
    }
    Query(querystring:string, ParameterInput:any ): Observable<any> {
        return this.http.post(this.endpoint + '/db/pg-query', {querystring:querystring,parms:ParameterInput} ) 
    }

}
