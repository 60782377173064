import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {Course} from "../model/course";
import {Observable, Subject} from "rxjs";
import {CoursesService} from "../services/courses.service";
import {map} from "rxjs/operators";
import {AppService} from '../services/app.service'
import {UserService} from '../services/user.service'

@Component({
    selector: 'login-page',
    templateUrl: './login-page.component.html',
    styleUrls: ['./login-page.component.scss']
})
export class LoginPageComponent implements OnInit {

    
  @Output() LogingEvents : EventEmitter<any> = new EventEmitter();

  LogingForm:Subject<any> = new Subject();

  password=''
  username=''
  
    beginnerCourses$: Observable<any>;

    advancedCourses$: Observable<Course[]>;
    HomePage:Subject<any> =  new Subject<any>()  ;
    currentScreenSize:string;
    captionlevelfontsize
    RankingSize:string = 'Large';
    cols=2
    lightimg:boolean= false;
    totalNumber = 'large'
    totallabel = 'small'
                                WelcomelabelfontSize ='32px'
                            UserNamelabelfontSize ='25px'
    pathD = 'M51,93.5C51,54.6,82.6,23,121.5,23h372.1c11.6,0,17.4,0,21.8,2.3c3.5,1.9,6.4,4.8,8.3,8.3C526,38,526,43.8,526,55.4v38.1v38.1c0,11.6,0,17.4-2.3,21.8c-1.9,3.5-4.8,6.4-8.3,8.3c-4.4,2.3-10.2,2.3-21.8,2.3H121.5	C82.6,164,51,132.4,51,93.5z';
    constructor(private AppService:AppService , private user:UserService ) {
        
    }

    ngAfterViewInit(): void {
        //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
        //Add 'implements AfterViewInit' to the class.
        console.log('ngAfterViewInit')
      }

    ngOnInit() {
      const  that = this
      console.log('ngOnInit')
        this.AppService.variables.subscribe(function(v){
            if( v.eventname== 'ChangeScreenSize'){
                that.currentScreenSize=v['value'].toString()
                console.log(that.currentScreenSize)
                that.setScreenSize()
           
            }
        })
        this.cols=3
        setTimeout(() => {
            that.setScreenSize()
        }, 200);
      
    }

    setScreenSize(){
       const that=this 
        switch (that.currentScreenSize) {
            case 'XLarge': 
                    that.cols=3;              
                    break;
            case 'Large': 
                    that.cols=3 ;
                    that.captionlevelfontsize='large';
                    that.RankingSize= 'Large'; 
                    that.totalNumber = 'large'
                    that.totallabel = 'x-small'
                    that.WelcomelabelfontSize ='32px'
                    that.UserNamelabelfontSize ='25px'
                    break;
            case 'Medium': 
                    that.cols=3;that.captionlevelfontsize='medium';
                    that.RankingSize= 'Medium';  
                    that.totalNumber = 'small'
                    that.totallabel = 'xx-small'
                    that.WelcomelabelfontSize ='25px'
                    that.UserNamelabelfontSize ='20px'
                    break;
            case 'Small': 
                    that.cols=3; that.captionlevelfontsize='medium'; 
                    that.RankingSize= 'Small'; 
                    that.totalNumber = 'x-small'
                    that.totallabel = 'xx-small'
                    that.WelcomelabelfontSize ='25px'
                    that.UserNamelabelfontSize ='20px'
                    break;
            case 'XSmall': 
                    that.cols=1;that.captionlevelfontsize='Smaller'; 
                    that.RankingSize= 'Large';                             
                    that.WelcomelabelfontSize ='32px'
                    that.UserNamelabelfontSize ='25px'
                    break;
            
        
            default:
                break;
        }
        setTimeout(() => {
            
            that.HomePage.next({eventname:'ChangeScreenSize',value:{RankingSize:that.RankingSize} })
        }, 200);
    }
    lightOpend (ev,open){
       // console.log(open)
        this.lightimg=open;    
        
    }

    Login( ){

        // alert(this.username )
       
         var _mylogin = this
         console.log(_mylogin.username)
      /*    this.user.login(_mylogin.username,_mylogin.password).then(function(logined:any){
    
          if(logined){
            if(logined._id){
              _mylogin.user.SetUserInfo(logined)
              _mylogin.LogingEvents.emit({parent:'app',name:'login success',data:logined })
            } else {
              if(logined.status){
                _mylogin.LogingEvents.emit({parent:'app',name:'error login',data:logined.status })
              } else {
                _mylogin.LogingEvents.emit({parent:'app',name:'error login',data:'Not login to system.' })
              }
            }
          } else {
            _mylogin.LogingEvents.emit({parent:'app',name:'error login',data:'Not login to system.' })
          }
         
    
         })  */
         
        
       
       }  
       
       PasswordInput(newvalue:any){
    
         this.password = newvalue.target.value
       }
       
       UserInput(newvalue:any){
         
         this.username = newvalue.target.value
       }

}
