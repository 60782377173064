
<div class="tree-demo-container">

  <h3>Nested Tree Demo</h3>

  <mat-tree
          class="example-tree mat-elevation-z4"
          [dataSource]="nestedDataSource" [treeControl]="nestedTreeControl">

      <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle>

          {{node.name}}

      </mat-tree-node>


      <mat-nested-tree-node  *matTreeNodeDef="let node; when: hasNestedChild">

          <div class="mat-tree-node">

              <button mat-icon-button matTreeNodeToggle>

                  <mat-icon>

                      {{ nestedTreeControl.isExpanded(node) ? "expand_more": "chevron_right" }}

                  </mat-icon>

              </button>

              {{node.name}}

          </div>

          <div class="nested-node"
               [class.example-tree-invisible]="!nestedTreeControl.isExpanded(node)">

              <ng-container matTreeNodeOutlet></ng-container>

          </div>

      </mat-nested-tree-node>


  </mat-tree>

  <h3>Flat Tree Demo</h3> 

   <mat-tree class="mat-elevation-z4"
             [dataSource]="flatDataSource" [treeControl]="flatTreeControl">


       <mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding>

           <div class="leaf-node">

               {{node.name}}

           </div>

       </mat-tree-node>


        <mat-tree-node *matTreeNodeDef="let node; when:hasFlatChild" matTreeNodePadding>

            <button mat-icon-button matTreeNodeToggle>
                <mat-icon>
                    {{flatTreeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
                </mat-icon>
            </button>

            {{node.name}}

        </mat-tree-node>



   </mat-tree>


</div>
