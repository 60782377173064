
<div class="about-container">

  <h1>Welcome!</h1>

  <p>Welcome to the Angular Material In Depth Course</p>

  <img class="course-image mat-elevation-z10"
       src="https://s3-us-west-1.amazonaws.com/angular-university/course-images/angular-material-course-1.jpg">

</div>





