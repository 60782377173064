import {Component} from '@angular/core';


@Component({
  selector: "create-course-step-2",
  templateUrl:"create-course-step-2.component.html",
  styleUrls: ["create-course-step-2.component.scss"]
})
export class CreateCourseStep2Component {

}
