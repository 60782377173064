import {Component, Inject, OnInit, ViewEncapsulation} from '@angular/core';
import {MAT_DIALOG_DATA,  MatDialog,  MatDialogConfig,  MatDialogRef} from '@angular/material/dialog';
import {Course} from "../model/course";
import {FormBuilder, Validators, FormGroup} from "@angular/forms";
import * as moment from 'moment';

@Component({
    selector: 'getotp-dialog',
    templateUrl: './getotp-dialog.component.html',
    styleUrls: ['./getotp-dialog.component.css']
})
export class GetOTPDialogComponent implements OnInit {

    description:string;

    form = this.fb.group({
        username: [this.userinfo.username, Validators.required],
        email: [this.userinfo.email,  Validators.required],
      
    });

    constructor(private fb: FormBuilder,
                @Inject(MAT_DIALOG_DATA) private userinfo:any,
                private dialogRef: MatDialogRef<GetOTPDialogComponent>) {

        

    }

    ngOnInit() {

    }

    close() {

        this.dialogRef.close();

    }

    save() {

        this.dialogRef.close(this.form.value);

    }
}


export function openGetOTPDialog(dialog: MatDialog, userinfo:any) {

    const config = new MatDialogConfig();

    config.disableClose = true;
    config.autoFocus = true;
    config.panelClass = "modal-panel";
    config.backdropClass = "backdrop-modal-panel";

    config.data = {
        ...userinfo
    };

    const dialogRef = dialog.open(GetOTPDialogComponent, config);

    return dialogRef.afterClosed();
}









