 
        <img mat-card-image src="../assets/img/AGC-VINYTHAI-Logo.png">
        <br>
        <img mat-card-image src="../assets/menu/divider.png">
        <!-- <mat-divider ></mat-divider> -->
        <!-- <mat-nav-list (click)="sidenav.close()"> -->       
            <mat-nav-list >
              
                    <a mat-list-item  routerLink="home" [routerLinkActive]="['is-active']">
                
                        
                        <img mat-card-image src="../assets/menu/Home.svg"  *ngIf="getNotactiveMenu('home')" >
                        <img mat-card-image src="../assets/menu/HomeSelect.svg"  *ngIf="getisactiveMenuText('home')" >
                    </a>
 
                    

            <a   mat-list-item  routerLink="ideas" [routerLinkActive]="['is-active']">
                <img mat-card-image src="../assets/menu/idea.svg"  *ngIf="getNotactiveMenu('ideas')">
                <img mat-card-image src="../assets/menu/ideaSelect.svg"  *ngIf="getisactiveMenuText('ideas')">
            </a>

            <a   mat-list-item  routerLink="dashboard" [routerLinkActive]="['is-active']">
                <img mat-card-image src="../assets/menu/Dashboard.svg" *ngIf="getNotactiveMenu('dashboard')">
                <img mat-card-image src="../assets/menu/DashboardSelect.svg" *ngIf="getisactiveMenuText('dashboard')">
            </a>

            <a mat-list-item  routerLink="reports" [routerLinkActive]="['is-active']">
                <img mat-card-image src="../assets/menu/Report.svg" *ngIf="getNotactiveMenu('reports')" [style.paddingTop.px]="10" >
                <img mat-card-image src="../assets/menu/ReportSelect.svg" *ngIf="getisactiveMenuText('reports')" [style.paddingTop.px]="10">
            </a>

            <a mat-list-item  routerLink="profile" [routerLinkActive]="['is-active']">
                <img mat-card-image src="../assets/menu/Profile.svg" *ngIf="getNotactiveMenu('profile')" [style.paddingTop.px]="10">
                <img mat-card-image src="../assets/menu/ProfileSelect.svg" *ngIf="getisactiveMenuText('profile')" [style.paddingTop.px]="10">
            </a>

            <a mat-list-item  routerLink="config"  [routerLinkActive]="['is-active']">
                <img mat-card-image src="../assets/menu/config.svg" *ngIf="getNotactiveMenu('config')" [style.paddingTop.px]="10">
                <img mat-card-image src="../assets/menu/configSelect.svg" *ngIf="getisactiveMenuText('config')" [style.paddingTop.px]="10">
            </a>

            <a mat-list-item routerLink="manual"  [routerLinkActive]="['is-active']">
                <img mat-card-image src="../assets/menu/manual.svg" *ngIf="getNotactiveMenu('manual')" [style.paddingTop.px]="10">
                <img mat-card-image src="../assets/menu/manualSelect.svg" *ngIf="getisactiveMenuText('manual')" [style.paddingTop.px]="10">
            </a>
            <a mat-list-item routerLink="hall-of-fame"  [routerLinkActive]="['is-active']">
                <img mat-card-image src="../assets/menu/hallOfFame.svg" *ngIf="getNotactiveMenu('hall-of-fame')" [style.paddingTop.px]="10">
                <img mat-card-image src="../assets/menu/hallOfFameSelect.svg" *ngIf="getisactiveMenuText('hall-of-fame')" [style.paddingTop.px]="10">
            </a>
            <!-- <label>{{routerLinkActive.toString() }}</label> -->
        </mat-nav-list>
       