

import {Injectable} from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import {Observable, ReplaySubject, Subject} from "rxjs";
import {Course} from "../model/course";
import {map} from "rxjs/operators";
import {Lesson} from "../model/lesson";
import { LocalStorageService, SessionStorageService, LocalStorage, SessionStorage } from 'angular-web-storage';
import {PostgreSQLService} from "./pg.service"
import {AppService} from "./app.service"
@Injectable()
export class UserService {
   public users = new Subject<any>();
   userinfo = {}
   //endpoint ='https://api.alonersoft.com:28143'
   endpoint ='http://localhost:28180'
    constructor(public local: LocalStorageService
            , public session: SessionStorageService
            ,public pg:PostgreSQLService
            ,public app:AppService
            ,private http:HttpClient
          ) { }
  
  /*         login(username:string,password:string){
            return   new Promise((resolve, reject) => {             
              this.app.login(username,password,this.varService.LocalGetValue('formmatinfo')  ).subscribe((output: any) => {
                if(output){
                  console.log(output)
                  resolve(output)
                }
              })
            })
          } */
        
        
   /*        getUserByID(id:string){
            
            this.api.gets('zusers',{id:'6325140c1da7e21a6b81d8c9'}).subscribe((output: any) => {
              console.log(output)
            })  
          }
          getUserByUsername(username:string){
            
            this.api.gets('zusers',{id:'6325140c1da7e21a6b81d8c9'}).subscribe((output: any) => {
              console.log(output)
            })  
          } */

          checkUserToken(){
            if (!this.app.LocalGetValue('UserToken')){
              console.log('New Login')
              //this.app.LocalSetValue('UserToken','**GenNewKey**')
              return 'not login'
            } else {

              let UserToken =  this.app.LocalGetValue('UserToken')
               return 'logined'
              console.log('UserToken:',UserToken)
            }          
          }
          findCourseById(courseId: number): Observable<Course> {
            return this.http.get<Course>(this.endpoint + `/user/courses/${courseId}`);
        }
          getOTPChangePassword(username:string,email:string){
             return this.http.get<any>(this.endpoint + `/user/otp/${username}/${email}`)             
          }
          ChangePassword(username:string,otp:string,newPassword:string){
            return this.http.get<any>(this.endpoint + `/user/editpassword/${username}/${otp}/${newPassword}`)             
         }
         Login(username:string,password:string){
          return this.http.get<any>(this.endpoint + `/user/login/${username}/${password}`)             
       }
          testAPI(){
             this.http.get<any>(this.endpoint + `/user`).subscribe(function(r){
              console.log(r)
             });

          }
          
        
/*           getAllrow(name:string){
            
            this.api.gets(name,{}).subscribe((output: any) => {
              console.log(output)
            })  
          }
         */
        /* 
          NewUser(){
            return   new Promise((resolve, reject) => {  
              
             this.api.post('zusers',{
              
              "User_id" : "chit",
              "username" : "chit",
              "password" : "passw0rd",
              "fullname" : "จิตกร กองลาแซ",
              "areaCode" : 10000,
              "email" : "Chitagorn@phk.ac.th",
              "level" : "3",
              "lineToken" : "Chittrakorn",
              "position" : "3",
              "active" : true,
              "lineID" : "loeimiller",
              "phoneNumber" : "0971753636"
            }).subscribe((output: any) => {
              console.log(output)
            })  
          })
        }
          */
        
        SetUserInfo(userLogin:any){
          this.app.LocalSetValue('UserInfo',userLogin)
        }
        

  
  }