<mat-tab-group stretchTabs="false">
    <mat-tab label="Idea List"> 
        <table-list-ideas style="width: 100%;margin-left: 30px;" >

        </table-list-ideas> 
    </mat-tab>
    <mat-tab label="Idea Detail"> Content 2 </mat-tab>
    <mat-tab label="+New Ideas"> Content 3 </mat-tab>
  </mat-tab-group>

