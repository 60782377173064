import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {HomeComponent} from "./home/home.component";
import {AboutComponent} from "./about/about.component";
import {CourseComponent} from "./course/course.component";
import {courseResolver} from "./services/course.resolver";
import {CreateCourseComponent} from './create-course/create-course.component';
import {DragDropComponent} from './drag-drop/drag-drop.component';
import {TreeDemoComponent} from './tree-demo/tree-demo.component';
import {VirtualScrollingComponent} from './virtual-scrolling/virtual-scrolling.component';
import {IdeasComponent  } from "./ideas/ideas.component";
import{DashboardComponent} from "./dashboard/dashboard.component"
import{ReportsComponent} from "./reports/reports.component"
import{ProfileComponent} from "./profile/profile.component"
import{ConfigComponent} from "./config/config.component"
import{ManualComponent} from "./manual/manual.component"
import{LoginPageComponent} from "./login/login-page.component"
import{HallOfFameComponent} from "./hall-of-fame/hall-of-fame.component"

const routes: Routes = [
  {
    path: "login",
    component: LoginPageComponent

},
    {
        path: "home",
        component: HomeComponent

    },

    {
        path: "ideas",
        component: IdeasComponent
    },
    {
      path: "dashboard",
      component: DashboardComponent
  },
  {
    path: "reports",
    component: ReportsComponent
},
{
  path: "profile",
  component: ProfileComponent
},
{
  path: "config",
  component: ConfigComponent
},

  
  {
    path: 'manual',
    component: ManualComponent
  },
  {
    path: "hall-of-fame",
    component: HallOfFameComponent
  }, 
    {
        path: "**",
        redirectTo: '/login'
    }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
